/* footer.css */
footer {
  background-color: #000;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* position: fixed;
    bottom: 0;
    width: 100%; */
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  padding-top: 40px;
}

.left-side {
  display: flex;
  margin-right: 50px; /* Adjusted margin */
}

.left-side a {
  margin-right: 30px; /* Adjusted margin */
  text-decoration: none;
  color: #fff; /* White text */
  transition: color 0.3s;
}

.left-side a:hover {
  color: #ff5722; /* Highlight color on hover */
}

.right-side button {
  background-color: #000;
  color: #fff;
  border: 2px solid #fff; /* White border */
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  margin-left: 50px; /* Adjusted margin */
}

.right-side button:hover {
  background-color: #fff;
  color: #000;
}

.custom-link {
  color: #fff; /* Set your desired color */
  text-decoration: none; /* Remove underline */
}

.custom-link:hover {
  color: #ccc; /* Change color on hover */
}
