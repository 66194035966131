.card {
  width: 30%; /* Adjust as needed */
  margin: 0 auto; /* Center horizontally */
  background-color: #fff; /* Change as needed */
  border: 1px solid #000; /* Black border */
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add shadow */
  padding: 20px;
}
.section-title {
  margin-bottom: 5px; /* Adjust as needed */
}
